<template>
  <div id="app">
    <a-spin :spinning="getLoading">
      <a-config-provider :locale="localData[locale]">
        <router-view />
      </a-config-provider>
    </a-spin>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enUS from 'ant-design-vue/lib/locale-provider/en_US';
export default {
  data() {
    return {
      locale: localStorage.getItem('lang') || 'en-US',
      localData: {
        'zh-CN': zhCN,
        'en-US': enUS
      }
    }
  },
  computed: {
    getLoading() {
      return this.$store.state.loading
    }
  },
  created() {
    this.$store.dispatch('storeActionWeb3');

    const token = localStorage.getItem('token');
    if (token) {
      this.$store.dispatch('storeActionUserInfo');
      this.$store.dispatch('storeActionGetCountryList');
      // this.$store.dispatch('storeActionAssetsList');
    }
  }
}
</script>

<style>
@import url(./common/common.scss);

html {
  --primary: #af94ff;
  --border: #545454;
  --gray: #aaaabf;
}

#app {
  box-sizing: border-box;
  /* width: 100vw; */
  background-color: #000000;
  color: #af94ff;
}

div {
  box-sizing: border-box;
}

.ant-btn-link {
  padding: 0 !important;
  height: 24px !important;
}

.gradientText {
  background-image: linear-gradient(
    to bottom,
    #8b5cf6 0%,
    #ffffff 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ant-form-item-label > label {
  color: #fff !important;
}

.ant-input {
  background-color: #141425 !important;
  color: #fff !important;
}
.ant-select-selection {
  background-color: #141425 !important;
  color: #fff !important;
}

.ant-select-arrow {
  color: #fff !important;
}

.ant-form-item-control {
  border-radius: 5px !important;
}
.ant-form-item-control-wrapper {
  border-radius: 5px !important;
}


</style>
