<template>
  <div class="assets">
    <div class="asset-title">
      <a-row>
        <a-col :span="8">Asset</a-col>
        <a-col :span="8">Balance</a-col>
        <a-col :span="8"></a-col>
      </a-row>
    </div>
    <div class="row">
      
      <div class="assets-item flex align-center" v-for="(value, key) in getAssetObj" :key="key">
        <img src="@/assets/img/coin-usdt.png" alt="">
        <div class="flex-1 amount flex">
          <div class="flex-1 label">{{ key }}</div>
          <div class="flex-1">{{value }}</div>
        </div>
        <div>
          <a-button type="link" icon="right-circle" @click="$router.push('/records/' + key)"></a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      list: [
        'USD',
        'USD$',
        'Token'
      ],
      wallet: [
        "USD_POINT",
        "HS_POINT",
        "TOKEN_POINT",
      ],
      // tokenPrice: 0
    }
  },
  computed: {
    getAssetObj() {
      let obj = {};
      let AssetsList = this.$store.state.AssetsList;
      console.log('AssetsList :>> ', AssetsList);
      if (AssetsList.length > 0) {
        AssetsList.forEach(item => {
          obj[item.coinName] = item.assetsAvailable.toFixed(2);
        })
      }
      return obj
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.assets {
  .asset-title {
    padding: 15px 0;
    color: var(--gray);
  }

  .assets-item {
    margin-bottom: 10px;
    padding: 15px 20px;
    border: 1px solid var(--primary);
    border-radius: 5px;
    img {
      width: 20px;
      height: 20px;
    }

    .amount {
      padding-left: 10px;

      .label {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}


</style>