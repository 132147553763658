import Vue from 'vue'
import Vuex from 'vuex'
import {
  webUserGetAssetsList,
  webUserGetUserInfo,
  webUserGetInviteLink,
  getCountryList
} from "@/api/index" // 接口

const Web3 = require("@/common/getWeb3");

Vue.use(Vuex);

let lang = localStorage.getItem('lang') || 'zh-CN';

export default new Vuex.Store({
  state: {
    loading: false,
    lang: lang,
    AssetsList: [],
    UserInfo: {},
    pageTitle: '主页',
    inviteInfo: {},
    countryList: [],
    web3: {},
    navObj: {}
  },
  getters: {
    twoStepStatus(state) {
      return state.UserInfo.twoCheckStatus;
    }
  },
  mutations: {
    changeLoadingState(state, val) {
      state.loading = val;
    },
    SET_StoreMutationUserInfo(state, item) {
      state.UserInfo = item;
    },
    SET_StoreMutationAssetsList(state, assets) {
      state.AssetsList = assets;
    },
    SET_StorePageTitle(state, val) {
      state.pageTitle = val;
    },
    SET_StoreInviteLink(state, val) {
      console.log('val :>> ', val);
      state.inviteInfo = val;
    },
    SET_StoreCountryList(state, val) {
      state.countryList = val;
    },
    SET_StoreMutationWeb3(state, item) {
      state.web3 = item;
    },
    SET_StoreNavList(state, list) {
      // state.navList = item;
      let newObj = {}
      list.forEach(item=>{
        newObj[item.id] = item.categoryName;
      })
      state.navObj = newObj;
    }
  },
  actions: {
    async storeActionUserInfo(context) {  // 获取用户信息
      const { data } = await webUserGetUserInfo({});
      if (data.code === 20000) {
        console.log('UserInfo :>> ', data.data);
        context.commit('SET_StoreMutationUserInfo', data.data);
      }
    },
    async storeActionAssetsList(context) {  // 获取钱包列表
      const { data } = await webUserGetAssetsList({});
      if (data.code === 20000) {
        let records = data.data.records;
        // console.log('Assets :>> ', records);
        context.commit('SET_StoreMutationAssetsList', records)
      }
    },
    async storeActionInviteLink(context) {  // 获取邀请信息
     
    },
    async storeActionGetCountryList(context) {  // 获取国家
      const { data } = await getCountryList({});
      // console.log('getCountryList :>> ', data.data);
      if (data.code === 20000) {
        let records = data.data;
        context.commit('SET_StoreCountryList', records)
      }
    },
    storeActionWeb3(context) {  // web3
      Web3.default.getWeb3().then((web3) => {
        context.commit('SET_StoreMutationWeb3', web3)
      });
    }
  },
  modules: {
    
  }
})
