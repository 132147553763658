<template>
  <div id="init">
    <div class="init-inner">
      <VHeader>
        <a-icon class="menu" type="menu" @click="showNavBar" style="font-size: 20px;"></a-icon>
        <!-- <span class="home-title" v-if="$route.path == '/home'">{{ $t('Common.' + $route.meta.title) }}</span> -->
      </VHeader>

      <div class="init-view">
        <RouterView></RouterView>
      </div>
    </div>

    <a-drawer
      title=""
      placement="left"
      :closable="false"
      :visible="visible"
      @close="onClose"
      width="350px"
    > 
      <div class="logo">
        Mining Machine 
      </div>
      <div class="username flex align-center justify-between">
        <span> {{ userName }} </span>
        <!-- <span> User </span> -->
      </div>
      <VNavbar :list="navList" @success="onClose"></VNavbar>
    </a-drawer>
  </div>
</template>

<script>
import VNavbar from "./navbar.vue";
import VFooter from "./footer.vue";
import VHeader from "./header.vue";
import Assets from "@/components/assets.vue";
import { getCategory } from '@/api';
export default {
  components: {
    VNavbar,
    VFooter,
    VHeader,
    Assets,
  },
  data() {
    return {
      collapsed: true,
      hide: window.innerWidth > 576 ? true : false,
      visible: false,
      navList: []
    };
  },
  computed: {
    getPageTitle() {
      return this.$store.state.pageTitle;
    },
    userInfo() {
      return this.$store.state.UserInfo;
    },
    userName() {
      let res = '';
      if(this.userInfo.userName) {
        res = this.clipUserName(this.userInfo.userName)
      }
      return res;
    }
  },
  methods: {
    clipUserName(name) {
      let res = '';
      if (name) {
        if (name.length > 10) {
          let start = name.slice(0, 4);
          let end = name.slice(-4);
          res = start + '******' + end;
        } else {
          res = name;
        }
      }
      return res;
    },
    onCollapse(collapsed, type) {
      // // console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      // // console.log(broken);
    },
    onMouseEnter() {
      this.collapsed = false;
    },
    onMouseLeave() {
      this.collapsed = true;
    },
    showNavBar() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    onBack() {
      this.$router.back();
    },
    async getNavList() {
      const { data } = await getCategory({});
      // console.log('getCategory :>> ', data.data);
      if(data.code === 20000) {
        this.navList = data.data;
        this.$store.commit('SET_StoreNavList', data.data);
      }
    }
  },
  created() {
    this.getNavList();
  }
};
</script>

<style lang="scss" scoped>
#init {
  width: 400px;
  min-height: 100vh;
  padding-bottom: 20px;
  margin: 0 auto;
}

.init-inner {
  padding: 0 15px;
}

.menu {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}

.home-title {
  padding-left: 10px;
  color: #fff;
}

::v-deep .ant-drawer-body {
  padding: 15px 0;
}

::v-deep .ant-drawer-content {
  padding: 0 15px;
  background: radial-gradient(
    circle at 76% 92%,
    #4b4b8b,
    #141425 31%
  )
}
::v-deep .ant-drawer-content-wrapper {
  border-radius: 0 0 45px 0;
  overflow: hidden;
}


.logo {
  color: var(--primary);
}

.username {
  margin-top: 10px;
  padding: 15px 0;
  color: #fff;
  border-bottom: 1px solid #232341;
  font-size: 12px;
}

@media screen and (max-width: 576px) {
  #init {
    width: 100%;
    border: 0;
  }
}
</style>
