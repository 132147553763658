<template>
  <div>
    <a-dropdown :trigger="['hover', 'click']">
      <div class="right-dropdown">

        <span class="name">{{ langText }}</span>
      </div>
      <a-menu slot="overlay">
        <a-menu-item v-for="(value, key) in langObj" :key="key">
          <a class="menu-item flex justify-end align-center" href="javascript:;" @click="switchLang(key)">
            {{ value }}
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'en-US',
      langObj: {
        'en-US': 'EN',
        'zh-CN': '简体中文',
        'zh-TW': '繁体中文',
        'ko-KP': '한국어',
        'ja-JP': '日本語',
      }
    }
  },
  computed: {
    langText() {
      return this.langObj[this.lang]
    }
  },
  methods: {
    switchLang(value) {
      localStorage.setItem('lang', value);
      this.lang = value;
      window.location.reload();
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.right-dropdown {
  width: 140px;
  text-align: right;
  cursor: pointer;
}
.name {
  display: inline-block;
  color: var(--primary);
  font-size: 16px;
}
.flag {
  height: 25px;
  margin-right: 10px;
  border-radius: 5px;
}

</style>