/**
 *  IP 信息
 */
let baseUrl, imageUrl, socketUrl, hashOrigin;

const origin = 'http://217.79.252.98:25080';
hashOrigin = 'http://217.79.252.98/hs-hash/web/#/';

switch (process.env.NODE_ENV) {
  case "development":

    baseUrl = origin + "/mining-machine-dapp";
    imageUrl = origin + "/mining-machine-dapp/";
    socketUrl = ''
    break;
  case "production":
    baseUrl = "https://stattermeta.io/mining-machine-dapp";
    imageUrl = "https://stattermeta.io/mining-machine-dapp/";
    break;
}

export { baseUrl, imageUrl, hashOrigin };
