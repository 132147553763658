import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/init.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home.vue'),
        meta: { title: '我的矿机' }
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('@/views/wallet/walletRecords.vue'),
        meta: { title: 'TRANSACTION HISTORY' }
      },
      {
        path: '/mining-index/:id',
        name: 'mining',
        component: () => import('@/views/machine/index.vue'),
        meta: { title: 'Mining' }
      },
      {
        path: '/mining/details/:id/:index',
        name: 'miningDetails',
        component: () => import('@/views/machine/listDetail.vue'),
        meta: { title: '详情' }
      },
      {
        path: '/mining/info',
        name: 'miningDetails',
        component: () => import('@/views/machine/listDetail2.vue'),
        meta: { title: '详情' }
      },
      // {
      //   path: '/mining/records',
      //   name: 'MiningRecords',
      //   component: () => import('@/views/machine/records.vue'),
      //   meta: { title: '购买记录' }
      // },
      // {
      //   path: '/mining/order',
      //   name: 'MiningOrder',
      //   component: () => import('@/views/machine/order.vue'),
      //   meta: { title: '待支付订单' }
      // },
      // {
      //   path: '/mining/order-details',
      //   name: 'MiningOrderDetails',
      //   component: () => import('@/views/machine/orderPay.vue'),
      //   meta: { title: '详情' }
      // },
      {
        path: '/mining/pay',
        name: 'MiningPay',
        component: () => import('@/views/machine/pay.vue'),
        meta: { title: '支付' }
      },
      {
        path: '/order/details',
        name: 'OrderDetails',
        component: () => import('@/views/order/details.vue'),
        meta: { title: '详情' }
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import('@/views/order/index.vue'),
        meta: { title: '我的订单' }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue'),
    meta: { title: 'Login' }
  },
  { path: '*', component: () => import("@/views/404.vue"), }
]

// 解决报错 NAVIGATION.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: 'hash'
})


// 不需要登录页面 name
const LOGIN_PAGE_NAME = ['Login', 'Register', '404', 'Forgot'];

// 跳转之前
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    next();
  } else {
    if (LOGIN_PAGE_NAME.includes(to.name)) {
      next();
    } else {
      next('/login');
    }
  }
  // next();
})
export default router
